import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { getTokenUserAndTenant } from '@utils/token';
import { useDispatch, useSelector } from 'react-redux';
import { UserByIdRequest } from '@cv/portal-idm-lib/user/user-Info/models';
import { formatUserData } from '@api/formatters';
import { getUserInfoByID } from '@cv/portal-idm-lib';
import { useConfig } from '@components/ConfigProvider';
import { UpdatedAccountData } from '@api/types';
import useCookie from './useCookie';
import { ACCOUNT_ACTIONS } from '@redux/actions';

enum TokenTypes {
  ID_TOKEN = 'idToken',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

const initialData = {
  title: '',
  name: '',
  tenantId: '',
  email: '',
  preferredTimeZone: '',
  mailingCountry: '',
  isPinConfigured: true,
  preferredLanguage: '',
  primaryPhone: '',
  secondaryPhone: '',
  homeAddress: {
    street: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
  },
  mailingAddress: {
    street: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
  },
  homeAddressSameAsMailingAddress: true,
  userName: {
    givenName: '',
    fathersName: '',
    mothersName: '',
  },
  notificationPreferences: {
    email: '',
    phone: '',
    services: [],
  },
  vehicles: [],
  roles: [],
  vehicleIds: [],
  _id: '',
  billingId: '',
  primarySubscriberVehicleIds: [],
};

const useAccount = (): UseQueryResult<UpdatedAccountData> => {
  const accessToken = useCookie(TokenTypes.ACCESS_TOKEN);
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const config = useConfig();
  const environment = config.getEnvironmentEnum();
  const dispatch = useDispatch();

  return useQuery({
    enabled: !!accessToken,
    queryKey: ['account', accessToken],
    queryFn: async ({ queryKey }) => {
      const parsedValues = getTokenUserAndTenant(accessToken);
      const userId = parsedValues.userId;

      const payload: UserByIdRequest = {
        environment,
        pathParams: {
          _id: userId,
        },
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
        },
      };

      const response = await getUserInfoByID(payload);

      return response.data;
    },
    select: (_data) => formatUserData(_data),
    placeholderData: initialData,
    onSuccess: (data) => {
      dispatch({ type: ACCOUNT_ACTIONS.SET_ACCOUNT_DATA, data });
    },
    onError: (error) => {
      console.error('An error on get account attempt:', error);
    },
  });
};

export default useAccount;
