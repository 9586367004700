import { useQuery } from '@tanstack/react-query';
import { getCookie } from '@utils/cookies';
import { milliseconds } from 'date-fns';

const useCookie = (cookieName: string) => {
  const { data } = useQuery({
    queryKey: ['cookie', cookieName],
    queryFn: async () => {
      const cookieValue = getCookie(cookieName);
      return cookieValue || '';
    },
    refetchInterval: milliseconds({ seconds: 1 }),
  });
  return data;
};

export default useCookie;
