import React from 'react';
import { Container, Stack, Typography, Box } from '@mui/material';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import InputCheckbox from '@components/InputCheckbox';
import Button from '@app/components-lib/components/Button';
import { LabelObject, extractPrimaries } from '@utils/labels';
import useAgreement from '@api/queries/useAgreement';
import Spinner from '@app/components-lib/components/Spinner/Spinner';
import PdfViewer from '@components/PdfViewer';

export type AcceptTermsProps = {
  labels: LabelObject;
  onSubmit: () => void;
};

const AcceptTerms = ({ labels, onSubmit }: AcceptTermsProps) => {
  const { termsTopHeader, termsSubHeader, termsTimeLabel, termsLegalText, termsButtonText } = extractPrimaries(labels);

  const [accepted, setAccepted] = React.useState(false);

  const { data, status } = useAgreement(AgreementType.Terms_of_service);

  if (status === 'loading') {
    return <Spinner />;
  }

  if (status === 'error') {
    throw new Error('Fetching of Terms and Conditions document is failed');
  }

  return (
    <Container>
      <Stack>
        <Typography
          component="h1"
          mb="2.25rem"
          fontSize="2.1rem"
          fontWeight="900"
          sx={(theme) => ({ marginTop: '1rem', [theme.breakpoints.up('md')]: { marginTop: '4rem' } })}
        >
          {termsTopHeader}
        </Typography>
        <Typography component="h2" fontSize="1.25rem" fontWeight="700">
          {termsSubHeader}
        </Typography>
        <Typography component="p" fontSize="1rem">
          {termsTimeLabel}
        </Typography>
        <PdfViewer pdfUrl={data?.agreementTemplateUrl || ''} />
        <Stack
          direction="row"
          sx={(theme) => ({ margin: '2rem 0', [theme.breakpoints.up('md')]: { margin: '2.5rem 0' } })}
        >
          <InputCheckbox checked={accepted} onChange={() => setAccepted(!accepted)} title="I accept" />
          <Typography component="p" ml="1rem">
            {termsLegalText}
          </Typography>
        </Stack>
        <Stack alignItems="center" sx={(theme) => ({ [theme.breakpoints.up('md')]: { marginBottom: '4rem' } })}>
          <Button
            disabled={!accepted}
            onClick={onSubmit}
            sx={{ textTransform: 'uppercase', width: '100%', maxWidth: '32rem' }}
          >
            {termsButtonText}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default AcceptTerms;
