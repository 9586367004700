import React from 'react';
import clsx from 'clsx';
import { useAnalytics } from '@cv/webframework-react-components';
import styles from './Tab.module.css';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';
import { useTheme } from '@mui/material';
import useVehicle from '@api/queries/useVehicle';

export type TabItemProps = {
  activeTab: 'none' | string;
  label: string;
  onClick: (label: string) => void;
  tabType: string;
  children: React.ReactNode;
  ['data-label']: string;
  className: string;
  tabItemContent: Array<object>;
  serviceTags: string[];
  analyticsEventName: string;
};

const TabItem = ({
  activeTab = 'none',
  label = '',
  onClick = () => {},
  tabType = 'primary',
  className,
  serviceTags,
  analyticsEventName,
}: Partial<TabItemProps>) => {
  const { data: vehicle, isFetching } = useVehicle();
  const { trackEvent } = useAnalytics();
  const theme = useTheme();

  if (isFetching) {
    return null;
  }

  if (serviceTags && !isServiceSubscribed(serviceTags, vehicle?.activeServices)) {
    return null;
  }

  const labelLowerCase = label.toLowerCase();
  const isActiveTab = labelLowerCase === activeTab;
  const isPrimaryTab = tabType === 'primary';

  const itemClassName = clsx(
    styles.TabItem,
    styles[`TabItem--${tabType}`],
    { [styles['TabItem--active']]: isActiveTab },
    className,
  );

  const activeStyle = isActiveTab
    ? { color: theme.palette.primary.main, borderBottom: `2px solid ${theme.palette.primary.main}` }
    : {};
  const activeStylePrimary =
    isActiveTab && isPrimaryTab
      ? { color: theme.palette.primary.main, backgroundColor: theme.palette.background.paper, borderBottom: 'none' }
      : {};
  const tabPrimary = isPrimaryTab
    ? { backgroundColor: theme.palette.primary.main, color: theme.palette.getContrastText(theme.palette.primary.main) }
    : {};

  return (
    <li className={styles.TabListItem} style={{ ...activeStyle, ...tabPrimary, ...activeStylePrimary }}>
      <button
        className={itemClassName}
        onClick={() => {
          if (analyticsEventName) trackEvent(analyticsEventName);
          onClick(labelLowerCase);
        }}
        style={isActiveTab && isPrimaryTab ? { color: 'inherit' } : {}}
      >
        {label}
      </button>
    </li>
  );
};

export default TabItem;
