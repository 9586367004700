import Card from './Card';
import InfoBox from './InfoBox';
import ContactInfoCard from './ContactInfoCard';
import RichTextContainer from './RichTextContainer';
import ExpandingList from './ExpandingList';
import OneColumnSection from './OneColumnSection';
import TwoColumnSection from './TwoColumnSection';
import AppLink from './AppLink';
import Link from './Link';
import FilteredNavigation from './Navigation/FilteredNavigation';
import EntryCreator from './EntryCreator';
import { Journey, Security, Subscription } from './Services';
import SubscriptionOverview from './SubscriptionOverview';
import HeroBanner from './HeroBanner';
import Disclaimer from './Disclaimer';
import RegistrationForm from './RegistrationForm';
import SecurityInfoBox from './SecurityInfoBox';
import ResetDataInfoBox from './ResetDataInfoBox';
import Login from './Login/Login';
import LoginCallBack from './Login/LoginCallBack';
import NotificationPreferences from './NotificationPreferences';
import ContentfulGrid from './ContentfulGrid';
import Fuel from './Fuel';
import LabelDictionary from './LabelDictionary';
import SubscriptionWrapper from './SubscriptionWrapper';
import LinkVehicle from './LinkVehicle';
import VinStatusPage from './VinStatusPage/VinStatusPage';
import { Tab, TabItem } from './Tab';
import { TabCurfew, TabValet, TabSpeed, TabBoundary } from './Map/MonitoringAlerts';
import DoorsAndTires from './DoorsAndTires';
import { VehicleCondition, VehicleConditionItem } from './VehicleCondition';
import { AlertHistoryContent } from './Map/MonitoringAlerts';
import Pagination from './Pagination';
import AgreementLink from './AgreementLink';
import Faq from './Faq';
import Contacts from './Contacts';
import VehicleImageContainer from './VehicleImageContainer/VehicleImageContainer';
import { VehicleImage } from './VehicleImage';
import CreditCardInfo from './CreditCardInfo';
import ResetPassword from './ResetPassword';
import UpdatePassword from './UpdatePassword';
import WarningBanner from '@components/WarningBanner';
import Carousel from '@components/Carousel';
import InfoCardContainer from '@components/InfoCard/InfoCardContainer';
import InfoCard from '@components/InfoCard/InfoCard';
import InfoCardContent from '@components/InfoCard/InfoCardContent';
import DataLoader from '@components/DataLoader';
import SecurityAuthentication from '@components/SecurityAuthentication/SecurityAuthentication';
import { SubscriptionManagementButtons } from './SubscriptionManagementButtons/SubscriptionManagementButtons';
import DownloadApp from './DownloadApp';
import CTAWiFiBlock from '@components/InfoCard/CTAWiFiBlock';
import Spacer from './Spacer';
import CTAVHRBlock from './InfoCard/CTAVHRBlock';
import CalendarAction from '@components/ActionCard/CalendarAction';
import PortalTargetComponent from './PortalTargetComponent';
import FormikAddressField from './FormikFields/FormikAddressField';
import ModalExpandingText from './ModalExpandingText';
import RedirectCustomer from '@components/RedirectCustomer';
import LabelList from './LabelList';
import ActionCard from '@components/ActionCard';
import LayoutItem from './ContentfulLayoutItem/LayoutItem';
import SendEmail from '@components/PasswordFlow/SendEmail';
import NewPassword from '@components/PasswordFlow/NewPassword';
import PasswordSuccess from '@components/PasswordFlow/Success';
import CodeResentMessage from '@components/PasswordFlow/CodeResentMessage';
import DataCollectionTerms from '@components/DataCollectionTerms';

const COMPONENT_MAPPINGS = {
  portalInfoBox: InfoBox,
  portalContactInfoCard: ContactInfoCard,
  portalInfoCard: Card,
  portalAppLink: AppLink,
  portalLink: Link,
  portalNavigation: FilteredNavigation,
  portalRichText: RichTextContainer,
  portalExpandingList: ExpandingList,
  oneColumnSection: OneColumnSection,
  twoColumnSection: TwoColumnSection,
  portalJourneySection: Journey,
  portalSecuritySection: Security,
  portalSubscriptionSection: Subscription,
  portalSubscriptionOverview: SubscriptionOverview,
  portalNewItemCreator: EntryCreator,
  portalHeroBanner: HeroBanner,
  portalDisclaimer: Disclaimer,
  portalUnauthorizedUserForm: RegistrationForm,
  portalSecurityInfoBox: SecurityInfoBox,
  portalResetPersonalDataInfoBox: ResetDataInfoBox,
  portalRegistrationForm: RegistrationForm,
  portalNotificationPreferences: NotificationPreferences,
  portalGrid: ContentfulGrid,
  portalMuiGrid: LayoutItem,
  portalLoginForm: Login,
  portalLoginCallBackForm: LoginCallBack,
  portalVehicleStatusFuel: Fuel,
  portalLabelDictionary: LabelDictionary,
  portalLinkVehicle: LinkVehicle,
  portalVinStatusPage: VinStatusPage,
  portalSecurityAuthenticationPage: SecurityAuthentication,
  portalTab: Tab,
  portalTabItem: TabItem,
  portalSpeedAlertTab: TabSpeed,
  portalBoundaryAlertTab: TabBoundary,
  portalCurfewAlertTab: TabCurfew,
  portalValetAlertTab: TabValet,
  portalVehicleStatusDoorsAndTires: DoorsAndTires,
  portalAlertHistoryTab: AlertHistoryContent,
  portalPagination: Pagination,
  portalTextWithLink: AgreementLink,
  portalVehicleWarningBannerSection: WarningBanner,
  portalVehicleImageContainer: VehicleImageContainer,
  portalVehicleImage: VehicleImage,
  portalVehicleStatusConditionSection: VehicleCondition,
  portalVehicleStatusConditionCard: VehicleConditionItem,
  portalContacts: Contacts,
  portalCCInfo: CreditCardInfo,
  resetPassword: ResetPassword,
  setPassword: UpdatePassword,
  portalCarousel: Carousel,
  portalSubscriptionComponent: SubscriptionWrapper,
  portalInfoBoxPopUpContainer: InfoCardContainer,
  portalInfoBoxPopup: InfoCard,
  portalInfoBoxPopUpContent: InfoCardContent,
  portalDataLoader: DataLoader,
  subscriptionManagementButtons: SubscriptionManagementButtons,
  portalDownloadApp: DownloadApp,
  portalCtaWiFiBlock: CTAWiFiBlock,
  portalCtaVhr: CTAVHRBlock,
  portalActionCard: ActionCard,
  portalSpacer: Spacer,
  portalTargetComponent: PortalTargetComponent,
  portalAddressField: FormikAddressField,
  modalExpandingText: ModalExpandingText,

  // --- DYNAMIC COMPONENTS

  // LabelDictionary
  FAQ: Faq,
  RedirectCustomer,
  LabelList,
  SendEmail,
  NewPassword,
  PasswordSuccess,
  CodeResentMessage,
  TenYear: DataCollectionTerms,

  CalendarBlock: CalendarAction,
};

export type MappedComponents = keyof typeof COMPONENT_MAPPINGS;
export type ComponentMapping = MappedComponents | 'portalAsset' | 'labelProperty';

export default COMPONENT_MAPPINGS;
