import { QueryClient } from '@tanstack/react-query';
import { milliseconds } from 'date-fns';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // prevent any refetching of data during some time
      // in case if we would fetch same data from different places simultaneously
      staleTime: milliseconds({ seconds: 5 }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

export default queryClient;
