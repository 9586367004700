import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useAnalytics } from '@cv/webframework-react-components';

import { ISxmKey, useApi } from '../../api';
import Dropdown from '@components/Dropdown';
import { IVehicle, setVehicleData, svlStatus } from '@redux/actions';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';
import ErrorModal from '@components/Login/ErrorModal';

import VehicleItem from './VehicleItem';
import urlSearchParams from '@utils/urlSearchParams';
import useTokenScope from '@hooks/useTokenScope';
import { setLoadingStatus } from '@redux/actions/loading';
import styles from './VehicleSwitcher.module.css';
import usePreferencesSelector from '@redux/selectors/preferences';
import config from '@config/config';
import { useHistory } from 'react-router';
import useVehicle from '@api/queries/useVehicle';
import useAllVehicles from '@api/queries/useAllVehicles';
import { cookiesVehicleIdKey } from '@constants';
import useAccount from '@api/queries/useAccount';

type VehicleSwitcherProps = {
  theme?: string;
  className?: string;
  svlService?: string;
};

export const maskVin = (vin?: string): string => {
  if (!vin) {
    return '';
  }

  return vin.replace(/^.{1,9}/, (character) => character.replace(/./g, '*'));
};

export const VehicleSwitcherContent = ({ theme, className, svlService }: VehicleSwitcherProps) => {
  const preferences = usePreferencesSelector();
  const api = useApi();
  const dispatch = useDispatch();
  const history = useHistory();

  const { sxmKeyDetails, vin } = urlSearchParams.getAll<{ sxmKeyDetails?: ISxmKey }>();
  const { canUseRts } = useTokenScope();

  const { data: vehicle } = useVehicle();
  const { data: vehicleData } = useAllVehicles();
  const { data: account } = useAccount();
  const primarySubscriberVehicleIds = account?.primarySubscriberVehicleIds || [];

  const [apiStatus, setApiStatus] = useState<'idle' | 'loading' | 'error' | 'success'>('success');
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const { trackEvent } = useAnalytics();

  const getSvl = useCallback(
    async (vehicleId) => {
      try {
        const { data } = await api.getSvl(vehicleId);
        setApiStatus('success');
        if (!isEmpty(data)) {
          dispatch(svlStatus(data));
        }
      } catch (err) {
        setApiStatus('error');
      }
    },
    [api, dispatch],
  );

  const updateVehicleDetails = useCallback(
    async (vehicle) => {
      dispatch(setVehicleData(vehicle));
    },
    [dispatch],
  );

  useEffect(() => {
    if (vehicle && isServiceSubscribed([svlService || ''], vehicle?.activeServices) && canUseRts) {
      getSvl(vehicle.vehicleId);
    }
  }, [vehicle, canUseRts, svlService]);

  const onVehicleSelected = async (selectedVehicle: IVehicle) => {
    try {
      dispatch(setLoadingStatus(true));
      await updateVehicleDetails(selectedVehicle);

      // mask first 9 symbols to have only 8 last visible
      const maskedVin = maskVin(selectedVehicle.vin);
      trackEvent(`vin:${maskedVin}`, 'maskedVin');

      // we need this to make <ManageSubscription /> reset after customer switch vehicle
      document.cookie = `${cookiesVehicleIdKey}=${selectedVehicle.vehicleId};`;
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };

  useEffect(() => {
    if (!vin) {
      return;
    }

    const _selectVehicle = vehicleData?.find((vehicleItem: IVehicle) => vehicleItem.vin === vin);
    _selectVehicle && onVehicleSelected(_selectVehicle);
  }, [vin]);

  useEffect(() => {
    const [firstVehicle] = vehicleData;
    if (vehicleData?.length) {
      onVehicleSelected(firstVehicle);
    } else {
      // track it here if we don't trigger onVehicleSelected initially
      const maskedVin = maskVin(vehicle?.vin || firstVehicle?.vin);
      trackEvent(`vin:${maskedVin}`, 'maskedVin');
    }
  }, [vehicleData]);

  useEffect(() => {
    if (!sxmKeyDetails?.vehicleId) {
      return;
    }

    const foundVehicle = vehicleData?.find((v) => v.vehicleId === sxmKeyDetails.vehicleId);
    if (foundVehicle) {
      onVehicleSelected(foundVehicle);
    }
  }, [sxmKeyDetails?.vehicleId, vehicleData]);

  if (vehicleData?.length <= 1 || apiStatus !== 'success' || !vehicle?.vehicleId) {
    return null;
  }

  const getVehicleLabel = (vehicle: IVehicle) => {
    return vehicle ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : '';
  };

  const redirectToVinStatus = config.get('DISPLAY_VIN_STATUS_PAGE');

  return (
    <>
      {showErrorDialog && (
        // TODO: combine all PS role error dialogs in one place
        <ErrorModal
          buttonOrientation="vertical"
          errorMessage={preferences.notLinkedVehicleErrorMessage}
          onCancel={() => setShowErrorDialog(false)}
          okLabel={redirectToVinStatus ? preferences.vinStatusButtonLabel : preferences.ownerPortalButtonLabel}
          onOk={() => {
            redirectToVinStatus ? history.push('/vinStatus') : window.location.replace(preferences.ownerPortalLink);
          }}
        />
      )}
      <Dropdown firstItemLabel={getVehicleLabel(vehicle)} theme={theme} classes={{ container: `mx-1 ${className}` }}>
        <ul className={styles['VehicleItemsList']}>
          {vehicleData
            ?.filter((e) => e.vehicleId !== vehicle.vehicleId)
            .map((e, i) => {
              const isPSRoleVehicle = primarySubscriberVehicleIds.includes(e.vehicleId);
              return (
                <VehicleItem
                  key={`vehicle-item-${i}`}
                  label={getVehicleLabel(e)}
                  onVehicleSelect={() => {
                    if (!isPSRoleVehicle) {
                      setShowErrorDialog(true);
                    } else {
                      onVehicleSelected(e);
                    }
                  }}
                  disabled={!isPSRoleVehicle}
                />
              );
            })}
        </ul>
      </Dropdown>
    </>
  );
};

type VehicleSwitcherWrapperProps = {
  pagesToHideVehicleSwitcher?: string[];
  children: JSX.Element;
};

const VehicleSwitcher = (props: VehicleSwitcherWrapperProps) => {
  const { pathname } = window.location;
  const showVehicleSwitcher = !!(pathname && !props.pagesToHideVehicleSwitcher?.includes(pathname));

  return <>{showVehicleSwitcher && props.children}</>;
};

export default React.memo(VehicleSwitcher);
