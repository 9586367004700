import { assign } from 'xstate';
import { send } from 'xstate/lib/actions';
import { enrollUser, searchUserShimNna } from '../../../../services/shim';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig, FormValuesNNa, UserProfileFlowContext } from '../Types';
import { FlowEventName } from '../../../flowTypes';
import { FetchMachineEvent } from '../../../fetchMachine';
import { OwnershipType } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';
import { ACCOUNT_SOURCE } from '@manageSubscription';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';

const maritzErrorCodes = ['MV0105', 'MSV105', 'MVO105', 'MRP105', 'MSP105'];

export const userProfileFlowNNA: ComponentFlowStateConfig<UserProfileFlowContext> = () => ({
  initial: 'searchUser',
  id: ComponentRoutes.userProfile,
  context: {
    queryStatus: 'pending',
  },
  states: {
    searchUser: {
      entry: 'setLoading',
      invoke: {
        id: 'searchUser',
        src: (context) => searchUserShimNna(context.subscriptionProps),
        onDone: {
          target: 'enrollUser',
        },
        onError: [
          {
            target: 'waitForInput',
            cond: (_, event) => {
              return event.data?.response?.status === 404;
            },
            actions: [
              assign({
                searchUserData: (_, event) => event.data,
              }),
            ],
          },
          {
            actions: [
              assign({
                maritzError: (_, event) => maritzErrorCodes.includes(event.data?.response?.data?.code),
              }),
              FlowEventName.NAVIGATE_TO_ERROR,
              'unsetLoading',
            ],
          },
        ],
      },
    },
    waitForInput: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.userProfile },
        }),
        'unsetLoading',
      ],
      on: {
        ENROLL_USER: 'enrollUser',
      },
    },
    enrollUser: {
      entry: 'setLoading',
      invoke: {
        id: 'enrollUser',
        src: async (context, event) => {
          const { subscriptionProps } = context;
          const { userDetails, vehicleDetails } = subscriptionProps;
          const values: FormValuesNNa = event?.data?.values || {};
          const customerEnrollment = !!event?.data?.values;
          try {
            const enrollUserResponse = await enrollUser(context.subscriptionProps, {
              termsAndConditions: false,
              vehicleEnrollment: true,
              customerEnrollment,
              customAttributes: {
                accountSource:
                  vehicleDetails.ownershipType === OwnershipType.Cpo ||
                  vehicleDetails.ownershipType === OwnershipType.PreOwned
                    ? ACCOUNT_SOURCE.DEALER
                    : ACCOUNT_SOURCE.CUSTOMER,
              },
              mobileNumber: values.phoneNumber,
              mobileNetworkOperator: values.operator?.substring(3),
              email: userDetails.email,
              address: {
                postalCode: values.zip,
                city: values.city,
                addressLine1: values.addressLine1,
                region: values.state,
                country: vehicleDetails.registrationCountry,
              },
              vehicle: {
                vin: vehicleDetails.vin,
              },
              firstName: userDetails.firstName || values.firstName,
              lastName: userDetails.lastName || values.lastName,
              password: values.password,
            });
            sendAnalyticsEvent(new EventDataBuilder(EventType.FormSucceededEvent).withArgs({ name: 'user profile' }));
            if (enrollUserResponse?.errorDetails?.errorDescription.includes('POO')) return Promise.reject('POO');
          } catch (e) {
            console.log('User login has failed. Reason: ', e);
            sendAnalyticsEvent(
              new EventDataBuilder(EventType.FormFailedEvent).withArgs({
                name: 'user profile',
                error: (e as Error).message,
              }),
            );
            assign({ queryStatus: 'error' });
          }
        },
        onDone: {
          target: 'fetchPackages',
        },
        onError: [
          {
            actions: [
              assign(() => ({
                queryStatus: 'POO',
              })),
              FlowEventName.NAVIGATE_TO_ERROR,
              'unsetLoading',
            ],
            cond: (_, event) => event.data === 'POO',
          },
          {
            actions: [
              assign((_, event) => ({
                queryStatus: event.data,
              })),
              'unsetLoading',
            ],
            target: 'waitForInput',
          },
        ],
      },
    },
    fetchPackages: {
      entry: FetchMachineEvent.fetchPackages(),
      on: {
        onFetchPackages: {
          actions: ['unsetLoading', 'navigateForward'],
        },
      },
    },
  },
});
