import { v4 as uuidv4 } from 'uuid';
import capitalize from 'lodash/capitalize';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { Country } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { DevicePrefLanguage } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';
import { getEffectiveAgreementTemplate } from '@cv/portal-cps-lib';

export type FetchAgreementOptions = {
  environment: Environment;
  accessToken: string;
  locale: string;
  tenantId: string;
  telematicsProgramId: string;
  agreementType: AgreementType;
  make: string;
  registrationCountry: string;
};

export const fetchAgreement = ({
  environment,
  accessToken,
  locale,
  tenantId,
  telematicsProgramId,
  agreementType,
  make,
  registrationCountry,
}: FetchAgreementOptions) => {
  return getEffectiveAgreementTemplate({
    environment,
    headers: {
      Authorization: accessToken,
      'CV-Correlation-Id': `CWP-${uuidv4()}`,
      'Accept-Language': locale,
      'CV-Tenant-Id': tenantId,
    },
    data: {
      telematicsProgramId,
      agreementType,
      make,
      country: Country[capitalize(registrationCountry)],
      language: DevicePrefLanguage[locale?.replace('-', '')],
    },
  }).then((res) => res.data?.payload);
};
