import React from 'react';
import { Container, ContentCenter, Heading, LineSeparator, Link, Title } from '../../styled-components/globalStyles';
import { formatDateByLocale } from '@cv/portal-common-lib';
import { LegalContainer } from './LegalContainer';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { DateSection } from './styles';
import { ContentfulAssets, TermsAndConditionsWebContent } from '..';
import { formatToCpsTZ } from '../utils';
import PdfViewer from '@components/PdfViewer';

interface TermsAndConditionsProps {
  declineTermsConfirmation: JSX.Element;
  acceptTerms: () => void;
  isDeclineTermsModalShown: boolean;
  toggleDeclineTermsModal: () => void;
  isLoading: boolean;
  termsAndConditions: { agreementTemplateUrl: string } | undefined;
  termsAgreed: boolean;
  setTermsAgreed: (agreed: boolean) => void;
  termsAndConditionsWebContent: TermsAndConditionsWebContent;
  assets: ContentfulAssets;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  declineTermsConfirmation,
  acceptTerms,
  isDeclineTermsModalShown,
  toggleDeclineTermsModal,
  isLoading,
  termsAndConditions,
  termsAgreed,
  assets,
  setTermsAgreed,
  termsAndConditionsWebContent: {
    header,
    description,
    effectiveAsOf,
    acceptTermsConfirmationMsg,
    termsErrorMessage,
    acceptTermsLabel,
    declineTermsExitEnrollLabel,
  },
}) => {
  return (
    <div data-testid="terms-and-conditions-component">
      {!isLoading && !termsAndConditions && (
        <Container>
          <ContentCenter>{termsErrorMessage}</ContentCenter>
        </Container>
      )}
      {!isLoading && termsAndConditions && (
        <Container>
          <Heading>{header}</Heading>
          <Title>{description}</Title>
          <DateSection>
            {effectiveAsOf} {formatDateByLocale(formatToCpsTZ())}
          </DateSection>
          <PdfViewer pdfUrl={termsAndConditions?.agreementTemplateUrl} defaultWidth={816} defaultHeight={1056} />
          <LineSeparator />
          <LegalContainer
            legalText={acceptTermsConfirmationMsg}
            setTermsAgreed={setTermsAgreed}
            termsAgreed={termsAgreed}
          />

          <Button onClick={acceptTerms} disabled={!termsAgreed}>
            {acceptTermsLabel}
          </Button>

          <Link onClick={toggleDeclineTermsModal}>{declineTermsExitEnrollLabel}</Link>

          <Modal
            assets={assets}
            content={declineTermsConfirmation}
            isShown={isDeclineTermsModalShown}
            hide={toggleDeclineTermsModal}
          />
        </Container>
      )}
    </div>
  );
};

export default TermsAndConditions;
