import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { LabelObject } from '@utils/labels';
import AcceptTerms from './AcceptTerms';
import TermsAccepted from './TermsAccepted';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import useDefaultPackages from '@api/queries/useDefaultPackages';
import { LoaderBackdrop } from '@components/Loader';
import useReconcileSubscribedPackages from '@api/queries/useReconcileSubscribedPackages';

enum Page {
  Terms = 'Terms',
  Final = 'Final',
}

export type DataCollectionTermsProps = {
  labels: LabelObject;
};

const DataCollectionTerms = ({ labels }: DataCollectionTermsProps) => {
  const { data: subscribedPackages, isFetched: isSubscribedFetched } = useSubscribedPackages();
  const { data: defaultEligiblePackages, isFetched: isDefaultFetched } = useDefaultPackages();
  const { reconcileSubscribedPackages } = useReconcileSubscribedPackages();
  const [page, setPage] = useState(Page.Terms);
  const history = useHistory();

  const isSubscribed = subscribedPackages?.subscribedPackages?.length;
  const isDefaultEmpty = !defaultEligiblePackages?.length;

  const handleSubmit = () => {
    setPage(Page.Final);
    reconcileSubscribedPackages();
  };

  if (!isSubscribedFetched || !isDefaultFetched) {
    return <LoaderBackdrop />;
  }

  if (isSubscribed || isDefaultEmpty) {
    isSubscribed && console.log('User is subscribed to packages');
    isDefaultEmpty && console.log('No default packages available');
    return <Redirect to="/profile" />;
  }

  return (
    <>
      {page === Page.Terms && <AcceptTerms onSubmit={handleSubmit} labels={labels} />}
      {page === Page.Final && <TermsAccepted labels={labels} goTo={() => history.push('/home')} />}
    </>
  );
};

export default DataCollectionTerms;
