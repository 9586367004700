// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AoZbE6ml_uXwuiaF3xgS {
  --card-color-border-top: var(--manage-subscription-border-color);
  max-width: 100%;
  margin: 0.7rem auto;
}

.aJTU4_NPL9TdLIAhhBrw {
  padding: 16px;
}

.yXAMELbAI7Z84cbwXt12 {
  border: 0;
}

.yXAMELbAI7Z84cbwXt12 > div {
  box-shadow: none;
}

@media (min-width: 600px) {
  .aJTU4_NPL9TdLIAhhBrw {
    padding: 32px;
  }

  .q75HH04OxVEQbEHKJnIO {
    width: 566px;
  }
}

@media (min-width: 768px) {
  .q75HH04OxVEQbEHKJnIO {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .q75HH04OxVEQbEHKJnIO {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .q75HH04OxVEQbEHKJnIO {
    width: 1170px;
  }
}

.AoZbE6ml_uXwuiaF3xgS p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubscriptionWrapper/SubscriptionWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,gEAAgE;EAChE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,SAAS;AACX","sourcesContent":[".subscription-wrapper {\n  --card-color-border-top: var(--manage-subscription-border-color);\n  max-width: 100%;\n  margin: 0.7rem auto;\n}\n\n.subscription-wrapper-inner {\n  padding: 16px;\n}\n\n.subscription-wrapper-without-border {\n  border: 0;\n}\n\n.subscription-wrapper-without-border > div {\n  box-shadow: none;\n}\n\n@media (--viewport-xs) {\n  .subscription-wrapper-inner {\n    padding: 32px;\n  }\n\n  .card-with-margin {\n    width: 566px;\n  }\n}\n\n@media (--viewport-s) {\n  .card-with-margin {\n    width: 750px;\n  }\n}\n\n@media (--viewport-m) {\n  .card-with-margin {\n    width: 970px;\n  }\n}\n\n@media (--viewport-l) {\n  .card-with-margin {\n    width: 1170px;\n  }\n}\n\n.subscription-wrapper p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscription-wrapper": `AoZbE6ml_uXwuiaF3xgS`,
	"subscription-wrapper-inner": `aJTU4_NPL9TdLIAhhBrw`,
	"subscription-wrapper-without-border": `yXAMELbAI7Z84cbwXt12`,
	"card-with-margin": `q75HH04OxVEQbEHKJnIO`
};
export default ___CSS_LOADER_EXPORT___;
