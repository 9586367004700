import React from 'react';
import { useSelector } from 'react-redux';

import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';

import { DialogWithCloser } from '../Dialog';
import { RootState } from '@app/reducers';
import { DialogWithCloserProps } from '@components/Dialog/DialogWithCloser';
import useVehicle from '@api/queries/useVehicle';

type AppWrapperErrorDialogProps = {
  services: string[];
  modalContent?: DialogWithCloserProps;
};

const AppWrapperErrorDialog = React.memo(({ services, modalContent }: AppWrapperErrorDialogProps) => {
  const { data: vehicle, isFetching } = useVehicle();

  if (isFetching) {
    return <></>
  }

  if (isServiceSubscribed(services, vehicle?.activeServices)) {
    return <></>;
  }

  return <DialogWithCloser {...modalContent} />;
});

export default AppWrapperErrorDialog;
