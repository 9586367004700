import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCookie } from '@utils/cookies';
import { RootState } from '@app/reducers';
import { TokenTypes } from '@api/services/StoreService';
import useVehicle from './useVehicle';
import { useConfig } from '@components/ConfigProvider';
import { fetchDefaultEligiblePackages } from '@api/requests/subscription';
import useAccount from './useAccount';

export const useDefaultPackages = () => {
  const { data: account } = useAccount();
  const userId = account?._id;
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale || 'en-US');
  const tenantId = useSelector(({ accountReducer }: RootState) => accountReducer?.tenantId || '');

  const config = useConfig();
  const environment = config.getEnvironmentEnum();

  const { data: vehicle } = useVehicle();
  const vehicleId = vehicle?.vehicleId;

  return useQuery({
    enabled: !!(vehicleId && userId),
    queryKey: ['defaultEligiblePackages', vehicleId],
    queryFn: async () => {
      const defaultPkgs = await fetchDefaultEligiblePackages({
        accessToken,
        tenantId,
        vehicleId,
        userId,
        environment,
        locale,
      });
      return defaultPkgs?.data || [];
    },
    placeholderData: [],
  });
};

export default useDefaultPackages;
