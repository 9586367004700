import React, { useMemo } from 'react';
import { useFlowMachine, useFlowMachineContext } from '../../../Router/RouterContext';
import { isEmpty, range } from 'lodash';
import { SxmUser } from '../../Types';
import * as Yup from 'yup';
import { useSelector } from '@xstate/react';
import { FormValues } from '../../../Router/flows/componentFlow/Types';
import UserProfile, { UserProfileYupShapeType } from './UserProfile';
import { validatePhoneNumber } from '@manageSubscription/utils';

const UserProfileContainerNNA = () => {
  const flowMachine = useFlowMachine();
  const searchUserData = useSelector(flowMachine, (state) => state.context.searchUserData);

  const {
    subscriptionProps,
    content: { commonWebContent, userProfileWebContent, nissanCpoWebContent },
  } = useFlowMachineContext();
  const {
    firstNameInputLabel,
    lastNameInputLabel,
    passwordInputLabel,
    passwordMinValueError,
    passwordValidationError,
    confirmPasswordInputLabel,
    confirmPasswordError,
    addressLineOneInputLabel,
    zipInputLabel,
    cityLabel,
    stateLabel,
    phoneNumberInputLabel,
    phoneNumberValidationError,
    requiredError,
  } = userProfileWebContent;

  const { sxmUser } = searchUserData ?? { sxmUser: {} as SxmUser };

  const hasDigitsRegex = /.*\d.*/;
  const hasUpperCaseRegexp = /.*[A-Z].*/;
  const hasLowerCaseRegexp = /.*[a-z].*/;
  const hasSpecialCharRegexp = /.*[!@#\$%\^\&*\)\(+=._-].*/;

  const yupShape: UserProfileYupShapeType = useMemo(() => {
    return {
      firstName: Yup.string().trim().required(`${firstNameInputLabel} ${requiredError}`),
      lastName: Yup.string().trim().required(`${lastNameInputLabel} ${requiredError}`),

      password: Yup.string()
        .trim()
        .required(`${passwordInputLabel} ${requiredError}`)
        .min(8, passwordMinValueError)
        .test('nissan-password-validation', function (value) {
          if (!value) return false;

          let matchesCount = 0;
          const substringLength = 3;
          value.match(hasDigitsRegex) && matchesCount++;
          value.match(hasUpperCaseRegexp) && matchesCount++;
          value.match(hasLowerCaseRegexp) && matchesCount++;
          value.match(hasSpecialCharRegexp) && matchesCount++;

          const lowerCaseValue = value.toLowerCase();
          const lowerCaseEmail = subscriptionProps?.userDetails.email?.toLowerCase();
          const emailOverlap =
            lowerCaseEmail &&
            range(lowerCaseValue.length + 1 - substringLength).some((i) =>
              lowerCaseEmail.includes(lowerCaseValue?.substring(i, i + substringLength)),
            );

          if (emailOverlap)
            return this.createError({
              path: this.path,
              message: passwordValidationError,
            });

          // we need at least two rules to return true
          if (matchesCount < 2)
            return this.createError({
              path: this.path,
              message: passwordValidationError,
            });

          return true;
        }),
      confirmPassword: Yup.string()
        .required(`${confirmPasswordInputLabel} ${requiredError}`)
        .oneOf([Yup.ref('password'), null], confirmPasswordError),
      addressLine1: Yup.string().required(`${addressLineOneInputLabel} ${requiredError}`),
      addressLine2: Yup.string(),
      zip: Yup.string().trim().required(`${zipInputLabel} ${requiredError}`),
      city: Yup.string().trim().required(`${cityLabel} ${requiredError}`),
      state: Yup.string().trim().required(`${stateLabel} ${requiredError}`),
      phoneNumber: Yup.string()
        .required(`${phoneNumberInputLabel} ${requiredError}`)
        .test('validation', phoneNumberValidationError, (value = '') => validatePhoneNumber(value)),
      operator: Yup.string().required(`${nissanCpoWebContent.mobileNetworkOperatorLabel} ${requiredError}`),
    };
  }, []);

  const handleSubmit = (values?: FormValues) => {
    flowMachine.send({
      type: 'ENROLL_USER',
      data: { values },
    });
  };

  const initialValues = {
    firstName: sxmUser?.firstName || '',
    lastName: sxmUser?.lastName || '',
    email: subscriptionProps?.userDetails.email || sxmUser?.email || '',
    password: '',
    confirmPassword: '',
    pin: '',
    question: '',
    answer: '',
  };

  const firstNameExisted = !isEmpty(initialValues.firstName);
  const lastNameExisted = !isEmpty(initialValues.lastName);

  return (
    <UserProfile
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      firstNameExisted={firstNameExisted}
      lastNameExisted={lastNameExisted}
      yupShape={yupShape}
      commonWebContent={commonWebContent}
      userProfileWebContent={userProfileWebContent}
      nissanCpoWebContent={nissanCpoWebContent}
    />
  );
};

export default UserProfileContainerNNA;
