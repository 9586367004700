import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { fetchAgreement } from '@api/requests/subscription';
import { useConfig } from '@components/ConfigProvider';
import { getCookie } from '@utils/cookies';
import { TokenTypes } from '@api/services/StoreService';
import { RootState } from '@app/reducers';
import useVehicle from './useVehicle';

const useAgreement = (agreementType: AgreementType) => {
  const config = useConfig();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale || 'en-US');
  const tenantId = useSelector(({ accountReducer }: RootState) => accountReducer?.tenantId || '');
  const { data = { vehicleId: '', telematicsProgramId: '', make: '', registrationCountry: '' } } = useVehicle();
  const { vehicleId, telematicsProgramId, make, registrationCountry } = data;

  return useQuery({
    queryKey: ['agreement', vehicleId, agreementType],
    queryFn: () =>
      fetchAgreement({
        environment: config.getEnvironmentEnum(),
        accessToken,
        locale,
        tenantId,
        telematicsProgramId,
        agreementType,
        make,
        registrationCountry,
      }),
    enabled: !!vehicleId && !!telematicsProgramId && !!make && !!registrationCountry,
  });
};

export default useAgreement;
